import { Header } from "../Header/Header"
import { YourFurniActivity } from "../YourFurniActivity/YourFurniActivity"

export const Content: React.FC = () => {
    return (
        <>
            <Header />
            <YourFurniActivity />
        </>
    )
}