import { IDataApi } from "../../Interfaces/DataApi"
import { FormUserLogin } from "../FormUserLogin/FormUserLogin"


export const Registration: React.FC = () => {

    return (
        <FormUserLogin
            isShowElement={true}
        />
    )
}